<template>
  <div style="">
    <!-- 优惠券详情 -->
    <van-nav-bar
      class="backBox"
      title="发放记录详情"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="page">
      <div class="box_2">
        <div class="header">
          <div class="box_3">
            <img src="../../../assets/img/parkP@2x.png" class="icon_2" />
            <span class="text-group_1">{{ detailInfo.plateNum }}</span>
          </div>
          <span class="statusText">{{couponStatusNameFormatter(detailInfo.status)}}</span>
        </div>
        <!-- <span class="text_10"></span> -->
        <div class="lineline"></div>
        <!-- 未使用 -->
        <!-- <div class="aliveCoupon" v-if="{{detailInfo.status==1}}"> -->
        <div class="aliveCoupon">
          <div>
            <!-- <span  class="text_10">折扣减免券</span> -->
            <div class="text-wrapper_6">
              <span class="text_12">ID</span>
              <span class="text_13">{{ detailInfo.granted_time }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_12">类型</span>
              <span class="text_13">{{ detailInfo.granted_time }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_12">优惠额度</span>
              <span class="text_13">{{ detailInfo.granted_time }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_12">状态</span>
              <span class="text_13" v-if="detailInfo.status == 2">已使用</span>
              <span
                class="text_13"
                v-if="detailInfo.status == 4 || detailInfo.status == 3"
                >已过期</span
              >
            </div>
             <div class="lineline"></div>
            <div class="text-wrapper_6">
              <span class="text_12">领券时间</span>
              <span class="text_13">{{ detailInfo.granted_time }}</span>
            </div>
            <div class="text-wrapper_6" v-if="detailInfo.status == 2">
              <span class="text_12">使用时间</span>
              <span class="text_13">{{
                formatDate(detailInfo.latest_used_time)
              }}</span>
            </div>
            <!-- 已过期 -->
            <div
              class="text-wrapper_6"
              v-if="detailInfo.status == 4 || detailInfo.status == 3"
            >
              <span class="text_12">过期时间</span>
              <span class="text_13">{{
                formatDate(detailInfo.expire_time)
              }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_12">有效期截止时间</span>
              <span class="text_13">{{ detailInfo.e_e_time }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_12">允许使用开始时间</span>
              <span class="text_13">{{ detailInfo.allow_s_time }}</span>
            </div>

            <div class="text-wrapper_6">
              <span class="text_12">允许使用结束时间</span>
              <span class="text_13">{{ detailInfo.allow_e_time }}</span>
            </div>
          </div>
          <div class="">
            <!-- 使用显示状态 -->

            <!-- 已使用 -->
          </div>
        </div>

        <div class="outofdateCoupon" v-if="detailInfo.status == 3"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as common from '../../../api/common'
import * as util from '../../../api/util'
export default {
  props: ['item'],
  data () {
    return {
      detailInfo: {},
      detailType: null,
      tabIndex: null
    }
  },
  mounted () {
    this.detailInfo = JSON.parse(decodeURIComponent(this.item))
    console.log('Item:', this.detailInfo)
  },
  computed: {

  },
  methods: {
    couponStatusNameFormatter (status) {
      return util.getCouponStatusName(status)
    },
    formatDate (date) {
      return date ? common.timeFormat(date, 'yyyy-MM-dd hh:mm:ss') : ''
    },
    onClickLeft () {
      this.$router.go(-1)
      // this.$router.replace({ path: '/myCoupon', query: { tab: this.tabIndex } })
    }

  }
}
</script>
<style lang="scss" scoped>
.page {
  // height: calc(100vh - 50px);
}
.box_2 {
  background-color: rgba(255, 255, 255, 1);
  /* width: 686rpx; */
  align-self: center;
  margin-top: 0.16rem;
  display: flex;
  flex-direction: column;
  // padding: 0.1rem;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.16rem 0.16rem 0 0.16rem;
    .box_3 {
      // width: 0.81rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      // margin: 0 1.27rem 0 1.15rem;
      .icon_2 {
        width: 0.2rem;
        height: 0.2rem;
      }
      .text-group_1 {
        overflow-wrap: break-word;
        color: #333;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.16rem;
        margin-left: 0.04rem;
      }
    }
    .statusText {
      font-size: 0.12rem;
      color: #38d500;
    }
  }

  .text_10 {
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 0.14rem;
    font-family: PingFangSC-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 0.2rem;
    align-self: center;
    margin-top: 0.06rem;
  }
  .text-wrapper_6 {
    width: 3.23rem;
    margin-top: 0.16rem;
    margin-bottom: 0.08rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 0 0.16rem;
    .text_12 {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
    .text_13 {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }
}
.lineline {

  height: 0.01rem;
  background-color: #e2e2e2;
  margin-top: 0.12rem;
  margin-bottom: 0rem;
}
</style>
